var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('notificationHistory.pageTitle'),"breadcrumb":[
      { text: _vm.$t('home'), href: '/' },
      {
        text: _vm.$t('notificationHistory.pageTitle'),
        active: true,
      } ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-justify-between tw-items-center"},[_c('div',{staticClass:"row tw-w-6/12"},[_c('div',{staticClass:"col-md-5 tw-space-y-1"},[_c('label',{staticClass:"form-col-label control-label"},[_vm._v(_vm._s(_vm.$t("notificationHistory.dateRange")))]),_c('a-range-picker',{attrs:{"allowClear":false,"format":"DD-MM-YYYY","valueFormat":"DD-MM-YYYY"},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('div',{staticClass:"col-md-7 tw-space-y-1"},[_c('label',{staticClass:"form-col-label control-label"},[_vm._v(_vm._s(_vm.$t("notificationHistory.notType")))]),_c('a-select',{staticClass:"tw-w-full",attrs:{"mode":"multiple","placeholder":"All","maxTagCount":2,"filterOption":function (input, option) { return option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0; },"options":_vm.not_type_list},model:{value:(_vm.model.not_type_id),callback:function ($$v) {_vm.$set(_vm.model, "not_type_id", $$v)},expression:"model.not_type_id"}})],1)])])]),_c('div',{staticClass:"tw-overflow-auto"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var record = ref.record;
return [_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.execute_time)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.finish_time)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.user_name)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(_vm._s(record.not_type))]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(_vm._s(record.not_method))]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(_vm._s(record.not_subject))]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(_vm._s(record.not_content))])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }