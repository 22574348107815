<template>
  <div>
    <ts-page-title
      :title="$t('notificationHistory.pageTitle')"
      :breadcrumb="[
        { text: $t('home'), href: '/' },
        {
          text: $t('notificationHistory.pageTitle'),
          active: true,
        },
      ]"
    />
    <ts-panel>
      <ts-panel-wrapper>
        <div class="tw-justify-between tw-items-center">
          <div class="row tw-w-6/12">
            <div class="col-md-5 tw-space-y-1">
              <label class="form-col-label control-label">{{
                $t("notificationHistory.dateRange")
              }}</label>
              <a-range-picker
                v-model="dateRange"
                :allowClear="false"
                format="DD-MM-YYYY"
                valueFormat="DD-MM-YYYY"
                @change="fetchData()"
              />
            </div>
            <div class="col-md-7 tw-space-y-1">
              <label class="form-col-label control-label">{{
                $t("notificationHistory.notType")
              }}</label>
              <a-select
                class="tw-w-full"
                mode="multiple"
                placeholder="All"
                :maxTagCount="2"
                :filterOption="
                  (input, option) =>
                    option.componentOptions.children[0].text
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                "
                v-model="model.not_type_id"
                :options="not_type_list"
              ></a-select>
            </div>
          </div>
        </div>
      </ts-panel-wrapper>
      <div class="tw-overflow-auto">
        <ts-table :columns="columns" :records="resources" :loading="loading">
          <template v-slot="{ record }">
            <td class="tw-whitespace-nowrap">
              {{ record.execute_time }}
            </td>
            <td class="tw-whitespace-nowrap">
              {{ record.finish_time }}
            </td>
            <td class="tw-whitespace-nowrap">
              {{ record.user_name }}
            </td>
            <td class="tw-whitespace-nowrap">{{ record.not_type }}</td>
            <td class="tw-whitespace-nowrap">{{ record.not_method }}</td>
            <td class="tw-whitespace-nowrap">{{ record.not_subject }}</td>
            <td class="tw-whitespace-nowrap">{{ record.not_content }}</td>
          </template>
        </ts-table>
      </div>
      <div class="d-flex justify-content-end tw-p-4">
        <ts-pagination
          v-model="pagination"
          @navigate="fetchData"
        ></ts-pagination>
      </div>
    </ts-panel>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";

export default {
  name: "notificationHistory",
  components: {},
  data() {
    return {
      showForm: false,
      loading: false,
      not_type_list: [],
      model: {
        not_type_id: [],
      },
      errors: new Errors(),
    };
  },
  computed: {
    ...mapState("notification/notificationHistory", [
      "resources",
      "pagination",
      "formModels",
    ]),
    columns() {
      return [
        {
          name: this.$t("notificationHistory.executeTime"),
          style: "width:30px",
        },
        { name: this.$t("notificationHistory.finishTime") },
        { name: this.$t("notificationHistory.user") },
        { name: this.$t("notificationHistory.notType") },
        { name: this.$t("notificationHistory.notMethod") },
        { name: this.$t("notificationHistory.notSubject") },
        { name: this.$t("notificationHistory.notContent") },
      ];
    },
    dateRange: {
      get() {
        return this.$store.state.notification.notificationHistory.dateRange;
      },
      set(newValue) {
        this.$store.commit(
          "notification/notificationHistory/SET_DATE_RANGE",
          newValue
        );
      },
    },
  },
  methods: {
    ...mapActions("notification/notificationHistory", ["getFormViewData"]),
    async fetchFormData() {
      this.loading = true;
      await this.getFormViewData({
        index: "notificationType",
        params: {
          fnName: "notificationType",
        },
      });
      this.not_type_list = this.formModels["notificationType"].map((el) => ({
        value: el.not_type_id,
        label: el.not_type,
      }));
      this.loading = false;
    },
    fetchData(attributes) {
      this.loading = true;
      this.$store
        .dispatch("notification/notificationHistory/fetch", {
          ...attributes,
          not_type_id: this.model.not_type_id,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$notify({ type: "error", text: error.message });
        });
    },
  },
  watch: {
    "model.not_type_id": debounce(function () {
      this.fetchData();
    }, 500),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchFormData();
      vm.fetchData();
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("notification/notificationHistory/RESET_STATE");
    next();
  },
};
</script>
